/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { devicesControllerGetHeatGenerators } from '../fn/devices-controller/devices-controller-get-heat-generators';
import { DevicesControllerGetHeatGenerators$Params } from '../fn/devices-controller/devices-controller-get-heat-generators';
import { devicesControllerGetSystems } from '../fn/devices-controller/devices-controller-get-systems';
import { DevicesControllerGetSystems$Params } from '../fn/devices-controller/devices-controller-get-systems';
import { HeatGeneratorsResponseGetRecordsList } from '../models/heat-generators-response-get-records-list';
import { SystemsResponseGetRecordsList } from '../models/systems-response-get-records-list';


/**
 * Represents all API calls related to installations and devices.
 */
@Injectable({ providedIn: 'root' })
export class DevicesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `devicesControllerGetHeatGenerators()` */
  static readonly DevicesControllerGetHeatGeneratorsPath = '/planning-projects/v1/devices/heat-generators/{countryCode}';

  /**
   * Gets all heat generator types along with energy source information.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `devicesControllerGetHeatGenerators()` instead.
   *
   * This method doesn't expect any request body.
   */
  devicesControllerGetHeatGenerators$Response(params: DevicesControllerGetHeatGenerators$Params, context?: HttpContext): Observable<StrictHttpResponse<HeatGeneratorsResponseGetRecordsList>> {
    return devicesControllerGetHeatGenerators(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets all heat generator types along with energy source information.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `devicesControllerGetHeatGenerators$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  devicesControllerGetHeatGenerators(params: DevicesControllerGetHeatGenerators$Params, context?: HttpContext): Observable<HeatGeneratorsResponseGetRecordsList> {
    return this.devicesControllerGetHeatGenerators$Response(params, context).pipe(
      map((r: StrictHttpResponse<HeatGeneratorsResponseGetRecordsList>): HeatGeneratorsResponseGetRecordsList => r.body)
    );
  }

  /** Path part for operation `devicesControllerGetSystems()` */
  static readonly DevicesControllerGetSystemsPath = '/planning-projects/v1/devices/systems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `devicesControllerGetSystems()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  devicesControllerGetSystems$Response(params: DevicesControllerGetSystems$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemsResponseGetRecordsList>> {
    return devicesControllerGetSystems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `devicesControllerGetSystems$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  devicesControllerGetSystems(params: DevicesControllerGetSystems$Params, context?: HttpContext): Observable<SystemsResponseGetRecordsList> {
    return this.devicesControllerGetSystems$Response(params, context).pipe(
      map((r: StrictHttpResponse<SystemsResponseGetRecordsList>): SystemsResponseGetRecordsList => r.body)
    );
  }

}
