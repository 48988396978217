/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CreateVariantResponse } from '../models/create-variant-response';
import { DeleteVariantResponse } from '../models/delete-variant-response';
import { DuplicateVariantResponse } from '../models/duplicate-variant-response';
import { SelectVariantResponse } from '../models/select-variant-response';
import { UpdateVariantResponse } from '../models/update-variant-response';
import { variantsControllerCreateVariant } from '../fn/variants-controller/variants-controller-create-variant';
import { VariantsControllerCreateVariant$Params } from '../fn/variants-controller/variants-controller-create-variant';
import { variantsControllerDeleteVariant } from '../fn/variants-controller/variants-controller-delete-variant';
import { VariantsControllerDeleteVariant$Params } from '../fn/variants-controller/variants-controller-delete-variant';
import { variantsControllerDuplicateVariant } from '../fn/variants-controller/variants-controller-duplicate-variant';
import { VariantsControllerDuplicateVariant$Params } from '../fn/variants-controller/variants-controller-duplicate-variant';
import { variantsControllerPatchVariant } from '../fn/variants-controller/variants-controller-patch-variant';
import { VariantsControllerPatchVariant$Params } from '../fn/variants-controller/variants-controller-patch-variant';
import { variantsControllerSelectVariant } from '../fn/variants-controller/variants-controller-select-variant';
import { VariantsControllerSelectVariant$Params } from '../fn/variants-controller/variants-controller-select-variant';


/**
 * Represents an API resource for project variants manipulation.
 */
@Injectable({ providedIn: 'root' })
export class VariantsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `variantsControllerCreateVariant()` */
  static readonly VariantsControllerCreateVariantPath = '/planning-projects/v1/projects/variants';

  /**
   * Creates new variant for project. Project variants helps to organize
   *         project in few possible installation variants, so that the user can choose the most suitable one.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `variantsControllerCreateVariant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerCreateVariant$Response(params: VariantsControllerCreateVariant$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateVariantResponse>> {
    return variantsControllerCreateVariant(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new variant for project. Project variants helps to organize
   *         project in few possible installation variants, so that the user can choose the most suitable one.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `variantsControllerCreateVariant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerCreateVariant(params: VariantsControllerCreateVariant$Params, context?: HttpContext): Observable<CreateVariantResponse> {
    return this.variantsControllerCreateVariant$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateVariantResponse>): CreateVariantResponse => r.body)
    );
  }

  /** Path part for operation `variantsControllerDeleteVariant()` */
  static readonly VariantsControllerDeleteVariantPath = '/planning-projects/v1/projects/variants/{id}';

  /**
   * Removes an existing project variant by it's ID. Project variants helps
   *         to organize project in few possible installation variants, so that the user can choose the
   *         most suitable one.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `variantsControllerDeleteVariant()` instead.
   *
   * This method doesn't expect any request body.
   */
  variantsControllerDeleteVariant$Response(params: VariantsControllerDeleteVariant$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteVariantResponse>> {
    return variantsControllerDeleteVariant(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes an existing project variant by it's ID. Project variants helps
   *         to organize project in few possible installation variants, so that the user can choose the
   *         most suitable one.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `variantsControllerDeleteVariant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  variantsControllerDeleteVariant(params: VariantsControllerDeleteVariant$Params, context?: HttpContext): Observable<DeleteVariantResponse> {
    return this.variantsControllerDeleteVariant$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteVariantResponse>): DeleteVariantResponse => r.body)
    );
  }

  /** Path part for operation `variantsControllerPatchVariant()` */
  static readonly VariantsControllerPatchVariantPath = '/planning-projects/v1/projects/variants/{id}';

  /**
   * Partially updates a variant with given ID, using partial variant structure.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `variantsControllerPatchVariant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerPatchVariant$Response(params: VariantsControllerPatchVariant$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateVariantResponse>> {
    return variantsControllerPatchVariant(this.http, this.rootUrl, params, context);
  }

  /**
   * Partially updates a variant with given ID, using partial variant structure.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `variantsControllerPatchVariant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerPatchVariant(params: VariantsControllerPatchVariant$Params, context?: HttpContext): Observable<UpdateVariantResponse> {
    return this.variantsControllerPatchVariant$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateVariantResponse>): UpdateVariantResponse => r.body)
    );
  }

  /** Path part for operation `variantsControllerSelectVariant()` */
  static readonly VariantsControllerSelectVariantPath = '/planning-projects/v1/projects/variants/select';

  /**
   * Selects a variant by provided `variantId` and deselects all other variants
   *         in given project (`projectId`). The variant that will be selected must be assigned to given project,
   *         otherwise the operation will return <b>400 Bad Request</b>. The field `isSelected` will be set to
   *         `true` for selected variant; all others selected variants will be deselected for given
   *         project, the field `isSelected` will be set to `false`.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `variantsControllerSelectVariant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerSelectVariant$Response(params: VariantsControllerSelectVariant$Params, context?: HttpContext): Observable<StrictHttpResponse<SelectVariantResponse>> {
    return variantsControllerSelectVariant(this.http, this.rootUrl, params, context);
  }

  /**
   * Selects a variant by provided `variantId` and deselects all other variants
   *         in given project (`projectId`). The variant that will be selected must be assigned to given project,
   *         otherwise the operation will return <b>400 Bad Request</b>. The field `isSelected` will be set to
   *         `true` for selected variant; all others selected variants will be deselected for given
   *         project, the field `isSelected` will be set to `false`.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `variantsControllerSelectVariant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerSelectVariant(params: VariantsControllerSelectVariant$Params, context?: HttpContext): Observable<SelectVariantResponse> {
    return this.variantsControllerSelectVariant$Response(params, context).pipe(
      map((r: StrictHttpResponse<SelectVariantResponse>): SelectVariantResponse => r.body)
    );
  }

  /** Path part for operation `variantsControllerDuplicateVariant()` */
  static readonly VariantsControllerDuplicateVariantPath = '/planning-projects/v1/projects/variants/{id}/duplicate';

  /**
   * Duplicates the variant with all the plannings.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `variantsControllerDuplicateVariant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerDuplicateVariant$Response(params: VariantsControllerDuplicateVariant$Params, context?: HttpContext): Observable<StrictHttpResponse<DuplicateVariantResponse>> {
    return variantsControllerDuplicateVariant(this.http, this.rootUrl, params, context);
  }

  /**
   * Duplicates the variant with all the plannings.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `variantsControllerDuplicateVariant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerDuplicateVariant(params: VariantsControllerDuplicateVariant$Params, context?: HttpContext): Observable<DuplicateVariantResponse> {
    return this.variantsControllerDuplicateVariant$Response(params, context).pipe(
      map((r: StrictHttpResponse<DuplicateVariantResponse>): DuplicateVariantResponse => r.body)
    );
  }

}
