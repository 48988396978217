import { Injectable } from '@angular/core';
import { Observable, filter, first, map, merge, withLatestFrom } from 'rxjs';
import { ProjectStore } from '../../state/project/project.store';
import { HeatPumpIntermediaryStore } from '../../state/heat-pump-intermediary/heat-pump-intermediary.store';
import { HeatPumpIntermediaryFormService } from './heat-pump-intermediary-form.service';
import { HeatPumpIntermediaryFormOptionsService } from './heat-pump-intermediary-form-options.service';
import { ProjectsService } from '../../services';
import {
    BuildingType,
    FormName,
    HeatLoadCalculationMethod,
    HeatPumpIntermediarySuccessMessages,
    ProjectType
} from '@customer-apps/shared/enums';
import { ProjectResponseGetById } from '@customer-apps/api-planning-projects';
import { AccordionService, HeatPumpIntermediaryState, SubsidyEligibility } from '@customer-apps/shared/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { HeatPumpIntermediaryPrefillService } from './heat-pump-intermediary-prefill.service';
import { Question } from '@customer-apps/shared/utils';
import { HeatPumpIntermediaryService } from './heat-pump-intermediary.service';
import { HeatPumpIntermediaryPrefillMapper } from './heat-pump-intermediary-prefill.mapper';

@Injectable({
    providedIn: 'root'
})
export class HeatPumpIntermediarySubsidyEligibilityService implements AccordionService {
    constructor(
        private projectStore: ProjectStore,
        private heatPumpIntermediaryStore: HeatPumpIntermediaryStore,
        private heatPumpIntermediaryService: HeatPumpIntermediaryService,
        private formService: HeatPumpIntermediaryFormService,
        private formOptionsService: HeatPumpIntermediaryFormOptionsService,
        private translate: TranslateService,
        private projectsService: ProjectsService,
        private prefillService: HeatPumpIntermediaryPrefillService,
        private prefillMapper: HeatPumpIntermediaryPrefillMapper
    ) {}

    public questions$ = merge(
        this.qustionsForRenovationProjectCalculatedByConsumption$(),
        this.qustionsForRenovationProjectCalculatedByOtherMethods$(),
        this.qustionsForNewCostructionProject$()
    );

    public getSuccessMessage(subsidyEligibility: SubsidyEligibility, projectType: ProjectType, buildingType: BuildingType): string {
        if (buildingType === BuildingType.MultiFamilyHouse) {
            return subsidyEligibility.numberOfResidentialUnits ? `${subsidyEligibility.numberOfResidentialUnits}` : '';
        }

        const message = (value: boolean) => {
            const text = value ? HeatPumpIntermediarySuccessMessages.Yes : HeatPumpIntermediarySuccessMessages.No;
            return this.translate.instant(text);
        };

        const messages = [
            message(subsidyEligibility?.isIncomeLowForSubsidy),
            message(subsidyEligibility?.isOwnerOfTheBuilding),
            message(subsidyEligibility?.isOwnerMainResidence)
        ];

        const isNewConstruction = this.projectsService.isNewConstruction(projectType);
        return (isNewConstruction ? messages : [...messages, message(subsidyEligibility?.isOldHeatingSystemDefective!)]).join(', ');
    }

    public isPrefillNeeded(): boolean {
        return !this.prefillService.isPrefilled(FormName.SubsidyEligibility);
    }

    public afterPrefill(): void {
        this.prefillService.setAsPrefilled(FormName.SubsidyEligibility);
    }

    private qustionsForRenovationProjectCalculatedByConsumption$(): Observable<Question[] | null> {
        return this.heatPumpIntermediaryStore.livingArea$.pipe(
            filter(Boolean),
            withLatestFrom(this.projectStore.project$, this.heatPumpIntermediaryStore.cache$, (livingArea, project, cache) => ({
                livingArea,
                project,
                cache
            })),
            filter(
                ({ project }) =>
                    this.projectsService.isRenovation(project!.projectType as ProjectType) &&
                    this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.Consumption) &&
                    this.heatPumpIntermediaryService.isSubsidyPossible(project!)
            ),
            map(({ livingArea, project, cache }) => {
                if (!livingArea) {
                    return null;
                }

                return this.returnQuestions(project!, cache);
            })
        );
    }

    private qustionsForRenovationProjectCalculatedByOtherMethods$(): Observable<Question[] | null> {
        return this.heatPumpIntermediaryStore.heatingDistribution$.pipe(
            withLatestFrom(this.projectStore.project$, this.heatPumpIntermediaryStore.cache$, (heatingDistribution, project, cache) => ({
                heatingDistribution,
                project,
                cache
            })),
            filter(
                ({ project }) =>
                    this.projectsService.isRenovation(project!.projectType as ProjectType) &&
                    !this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.Consumption) &&
                    this.heatPumpIntermediaryService.isSubsidyPossible(project!)
            ),
            map(({ heatingDistribution, project, cache }) => {
                if (!heatingDistribution) {
                    return null;
                }

                return this.returnQuestions(project!, cache);
            })
        );
    }

    private qustionsForNewCostructionProject$(): Observable<Question[] | null> {
        return this.heatPumpIntermediaryStore.heatingDistribution$.pipe(
            withLatestFrom(this.projectStore.project$, this.heatPumpIntermediaryStore.cache$, (heatingDistribution, project, cache) => ({
                heatingDistribution,
                project,
                cache
            })),
            filter(
                ({ project }) =>
                    this.projectsService.isNewConstruction(project!.projectType as ProjectType) &&
                    this.heatPumpIntermediaryService.isSubsidyPossible(project!)
            ),
            map(({ heatingDistribution, project, cache }) => {
                if (!heatingDistribution) {
                    return null;
                }

                return this.returnQuestions(project!, cache);
            })
        );
    }

    private returnQuestions(project: ProjectResponseGetById, cache: HeatPumpIntermediaryState | undefined) {
        const cachePrefill = this.prefillMapper.stateToSubsidyPrefill(cache?.subsidyEligibility);
        const projectPrefill = this.prefillMapper.projectToSubsidyPrefill(project);
        const options = this.formOptionsService.getSubsidyEligibilityFormOptions({
            prefill: cachePrefill || projectPrefill,
            projectType: project?.projectType as ProjectType,
            buildingType: project?.building?.buildingType as BuildingType
        });
        return this.formService.getSubsidyEligibilityQuestions(options);
    }

    public isExpanded$ = merge(this.isSubmitedPreviousFormSameValue$(), this.isPrefillCompleted$());

    public isSubmitedPreviousFormSameValue$(): Observable<boolean> {
        return this.heatPumpIntermediaryStore.sameValueSubmited$.pipe(
            withLatestFrom(this.projectStore.project$, (form, project) => ({ form, project })),
            map(({ form, project }) => {
                const afterLivingAreaForm =
                    form?.formName === FormName.LivingArea &&
                    this.projectsService.isRenovation(project!.projectType as ProjectType) &&
                    this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.Consumption);

                const afterHeatingDistributionForm = form?.formName === FormName.HeatingDistribution;

                return afterLivingAreaForm || afterHeatingDistributionForm;
            })
        );
    }

    public isPrefillCompleted$(): Observable<boolean> {
        return this.prefillService.state$.pipe(
            map(prefillState => prefillState[FormName.SubsidyEligibility].isPrefilled),
            filter(Boolean),
            first()
        );
    }
}
