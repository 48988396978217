/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PartnersListResponseGetRecordsList } from '../../models/partners-list-response-get-records-list';

export interface PartnersControllerGetPartners$Params {

/**
 * Sales organization code, related to the country code.
 */
  salesOrganisation: string;

/**
 * A search string for customers searching - may include partial name,
 *     customer number or other fragment of partner data, like address.
 */
  searchString: string;
}

export function partnersControllerGetPartners(http: HttpClient, rootUrl: string, params: PartnersControllerGetPartners$Params, context?: HttpContext): Observable<StrictHttpResponse<PartnersListResponseGetRecordsList>> {
  const rb = new RequestBuilder(rootUrl, partnersControllerGetPartners.PATH, 'get');
  if (params) {
    rb.query('salesOrganisation', params.salesOrganisation, {});
    rb.query('searchString', params.searchString, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PartnersListResponseGetRecordsList>;
    })
  );
}

partnersControllerGetPartners.PATH = '/planning-projects/v1/partners';
