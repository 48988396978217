import { createReducer, on } from '@ngrx/store';
import { FormKeys } from '@customer-apps/shared/enums';
import { Device, HeatPumpIntermediaryState } from '@customer-apps/shared/interfaces';
import { getSelectedSourceFromGenerators } from '../../shared/utils/selected-energy-source.helper';
import { HeatPumpIntermediaryActions } from './heat-pump-intermediary.actions';
import { percentToFraction } from '@customer-apps/shared/utils';
import { LodashService } from '@customer-apps/shared/services';

export const initialState: HeatPumpIntermediaryState = {};

export const heatPumpIntermediaryReducer = createReducer(
    initialState,
    on(HeatPumpIntermediaryActions.submitDevice, (state, formValue): HeatPumpIntermediaryState => {
        const isOldDeviceUsed = { value: (<Device>formValue)[FormKeys.IsOldDeviceUsed] };
        return {
            ...initialState,
            isOldDeviceUsed,
            cache: {
                ...state.cache,
                isOldDeviceUsed
            }
        };
    }),
    on(HeatPumpIntermediaryActions.submitSubsidyEligibility, (state, formValue): HeatPumpIntermediaryState => {
        const subsidyEligibility = {
            isIncomeLowForSubsidy: { value: formValue[FormKeys.IsIncomeLowForSubsidy] },
            isOwnerOfTheBuilding: { value: formValue[FormKeys.IsOwnerOfTheBuilding] },
            isOwnerMainResidence: { value: formValue[FormKeys.IsOwnerMainResidence] },
            isOldHeatingSystemDefective: LodashService.isNil(formValue[FormKeys.IsOldHeatingSystemDefective])
                ? undefined
                : { value: formValue[FormKeys.IsOldHeatingSystemDefective]! },
            numberOfResidentialUnits:
                formValue[FormKeys.NumberOfResidentialUnits] !== undefined
                    ? { value: formValue[FormKeys.NumberOfResidentialUnits] }
                    : undefined
        };
        return {
            ...state,
            subsidyEligibility,
            cache: {
                ...state.cache,
                subsidyEligibility
            }
        };
    }),
    on(HeatPumpIntermediaryActions.submitBuilding, (state, formValue): HeatPumpIntermediaryState => {
        const building = formValue;
        return {
            ...state,
            heatingEnergySource: undefined,
            energyConsumption: undefined,
            heatingDistribution: undefined,
            consumptionProfiles: undefined,
            heatGeneratorDetails: undefined,
            livingArea: undefined,
            building,
            cache: {
                ...state.cache,
                building
            }
        };
    }),
    on(HeatPumpIntermediaryActions.submitLivingArea, (state, formValue): HeatPumpIntermediaryState => {
        const livingArea = {
            value: formValue.livingArea
        };
        return {
            ...state,
            heatingEnergySource: undefined,
            energyConsumption: undefined,
            heatingDistribution: undefined,
            consumptionProfiles: undefined,
            heatGeneratorDetails: undefined,
            livingArea,
            cache: {
                ...state.cache,
                livingArea
            }
        };
    }),
    on(
        HeatPumpIntermediaryActions.getHeatGeneratorsSuccess,
        (state, { heatGenerators, selectedEnergySourceType, selectedHeatGeneratorType }): HeatPumpIntermediaryState => {
            if (selectedEnergySourceType && selectedHeatGeneratorType) {
                const heatingEnergySource = {
                    selectedEnergySource: getSelectedSourceFromGenerators(
                        heatGenerators,
                        selectedHeatGeneratorType,
                        selectedEnergySourceType
                    ),
                    selectedHeatGenerator: selectedHeatGeneratorType
                };

                return {
                    ...state,
                    heatGenerators,
                    heatingEnergySource,
                    cache: {
                        ...state.cache,
                        heatingEnergySource
                    }
                };
            }

            return { ...state, heatGenerators };
        }
    ),
    on(HeatPumpIntermediaryActions.getConsumptionProfilesSuccess, (state, { consumptionProfiles }): HeatPumpIntermediaryState => {
        return { ...state, consumptionProfiles };
    }),
    on(HeatPumpIntermediaryActions.resetHeatPumpIntermediary, (): HeatPumpIntermediaryState => {
        return { ...initialState };
    }),
    on(HeatPumpIntermediaryActions.submitHeatGenerator, (state, formValue): HeatPumpIntermediaryState => {
        const heatingEnergySource = {
            selectedEnergySource: getSelectedSourceFromGenerators(
                state.heatGenerators!,
                formValue.heatGenerator,
                formValue.specificEnergySource
            ),
            selectedHeatGenerator: formValue.heatGenerator
        };
        return {
            ...state,
            energyConsumption: undefined,
            heatingDistribution: undefined,
            consumptionProfiles: undefined,
            heatGeneratorDetails: undefined,
            heatingEnergySource,
            cache: {
                ...state.cache,
                heatingEnergySource
            }
        };
    }),
    on(HeatPumpIntermediaryActions.submitHeatGeneratorDetails, (state, formValue): HeatPumpIntermediaryState => {
        const heatGeneratorDetails = {
            boilerType: formValue.boilerType,
            heatPumpOperatingMode: formValue?.heatPumpOperatingMode,
            lowTemperature: formValue.boilerTypeSubquestion?.lowTemperature,
            warmWaterByHeatGenerator: formValue.boilerTypeSubquestion?.warmWaterByHeatGenerator,
            gasLossExhaust: percentToFraction(formValue.gasLossExhaust),
            gasLossExhaustKnown: formValue.gasLossExhaustKnown?.isKnown,
            installationYear: formValue.installationYear,
            nominalPower: formValue.nominalPower
        };
        return {
            ...state,
            energyConsumption: undefined,
            heatingDistribution: undefined,
            consumptionProfiles: undefined,
            heatGeneratorDetails,
            cache: {
                ...state.cache,
                heatGeneratorDetails
            }
        };
    }),
    on(HeatPumpIntermediaryActions.submitEnergyConsumption, (state, formValue): HeatPumpIntermediaryState => {
        const energyConsumption = {
            requiredEnergyPerYear: formValue.requiredEnergyPerYear,
            nonHeatingElectricityPerYear: formValue.nonHeatingElectricityPerYear
        };

        return {
            ...state,
            heatingDistribution: undefined,
            consumptionProfiles: undefined,
            energyConsumption,
            cache: {
                ...state.cache,
                energyConsumption
            }
        };
    }),
    on(HeatPumpIntermediaryActions.submitHeatingDistribution, (state, formValue): HeatPumpIntermediaryState => {
        const heatingDistribution = {
            distributionMethod: formValue.distributionMethod,
            heatingFlowTemperature: formValue.heatingFlowTemperature,
            heatingSchedule: formValue.heatingSchedule
        };
        return {
            ...state,
            heatingDistribution,
            cache: {
                ...state.cache,
                heatingDistribution
            }
        };
    }),
    on(HeatPumpIntermediaryActions.getHeatingSystemsSuccess, (state, action) => {
        return { ...state, heatingSystems: action.heatingSystems };
    }),
    on(HeatPumpIntermediaryActions.submitSameValue, (state, action) => {
        const { formName, value } = action;
        return { ...state, sameValueSubmited: { formName, value } };
    }),
    on(HeatPumpIntermediaryActions.resetSameValue, state => {
        return { ...state, sameValueSubmited: null };
    })
);
