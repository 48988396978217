/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { infoControllerGetServerInfo } from '../fn/info-controller/info-controller-get-server-info';
import { InfoControllerGetServerInfo$Params } from '../fn/info-controller/info-controller-get-server-info';
import { ServerInfoResponse } from '../models/server-info-response';


/**
 * Represents a special API resource containing endpoints providing information about the server.
 */
@Injectable({ providedIn: 'root' })
export class InfoControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `infoControllerGetServerInfo()` */
  static readonly InfoControllerGetServerInfoPath = '/planning-projects/v1/info';

  /**
   * Returns a basic information about a server, like version, build number or environment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoControllerGetServerInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  infoControllerGetServerInfo$Response(params?: InfoControllerGetServerInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<ServerInfoResponse>> {
    return infoControllerGetServerInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a basic information about a server, like version, build number or environment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoControllerGetServerInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  infoControllerGetServerInfo(params?: InfoControllerGetServerInfo$Params, context?: HttpContext): Observable<ServerInfoResponse> {
    return this.infoControllerGetServerInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServerInfoResponse>): ServerInfoResponse => r.body)
    );
  }

}
