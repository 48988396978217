/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CitiesResponseGetRecordsList } from '../../models/cities-response-get-records-list';

export interface LocationsControllerGetCities$Params {

/**
 * ISO standard Alpha-2 country code.The building address country code.
 */
  countryCode: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';

/**
 * Building postal code fragment or city name fragment to search for matching cities and post codes records. Minimum one character is required.
 */
  searchString: string;
}

export function locationsControllerGetCities(http: HttpClient, rootUrl: string, params: LocationsControllerGetCities$Params, context?: HttpContext): Observable<StrictHttpResponse<CitiesResponseGetRecordsList>> {
  const rb = new RequestBuilder(rootUrl, locationsControllerGetCities.PATH, 'get');
  if (params) {
    rb.query('countryCode', params.countryCode, {});
    rb.query('searchString', params.searchString, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CitiesResponseGetRecordsList>;
    })
  );
}

locationsControllerGetCities.PATH = '/planning-projects/v1/locations/cities';
